const nav_items = [
  {
    name: "Info",
    data: [
      {
        header_name: "Algemeen",
        sub_menus: [
          {
            name: "Medewerker",
            to: "Employees",
            roles: ["user"],
          },
          {
            name: "Onderhoud",
            to: "ManageEmployees",
            roles: ["admin", "hr", "marketing", "it"],
          },
          {
            name: "Afwezigheid",
            to: "absenceregistration",
            roles: ["user"],
          },
          {
            name: "Aangevraagde medewerkers",
            to: "requestedemployees",
            roles: ["admin", "it", "hr"],
          },
          {
            name: "Medewerker aanvragen",
            to: "nieuwewerknemer",
            roles: ["admin", "hr", "it", "managers", "management"],
          },
          {
            name: "Nieuws plaatsen",
            to: "nieuwsposter",
            roles: ["admin", "hr", "it", "marketing", "secretariaat"],
          },
          {
            name: "Bestanden uploaden",
            to: "bestanden",
            roles: ["secretariaat", "hr", "admin", "it", "management"],
          },
          {
            name: "Bestanden onderhoud",
            to: "managefiles",
            roles: ["hr", "management", "it", "admin", "secretariaat"],
          },
          {
            name: "Near miss indienen",
            to: "nearmiss",
            roles: ["user"],
          },
          {
            name: "Near miss overzicht",
            to: "nearmissincident",
            roles: ["management", "admin", "veiligheid"],
          },
        ],
      },
    ],
  },
  {
    name: "Verkopen",
    data: [
      {
        header_name: "Trucks",
        sub_menus: [
          {
            name: "Verkoop orders totaal",
            to: "Verkooporders",
            param_name: "bu",
            param: "200",
            roles: ["admin", "management", "delivery"],
          },
          {
            name: "Gereserveerd per inkoper",
            to: "Gereserveerd",
            param_name: "bu",
            param: "200",
            roles: ["admin", "management", "inkoper"],
          },
          {
            name: "Verkoop orders",
            to: "verkooporderperpersoon",
            param_name: "bu",
            param: "200",
            roles: ["admin", "management", "user"],
          },
          {
            name: "Afleveringen per dag",
            to: "afleveringenperdag",
            param_name: "bu",
            param: "200",
            roles: ["admin", "it", "verkopen_trucks", "management"],
          },
          {
            name: "Afleveringen per week",
            to: "afleveringenperweek",
            param_name: "bu",
            param: "200",
            roles: ["admin", "management", "delivery"],
          },
          {
            name: "Afleveringen per maand",
            to: "afleveringenpermaand",
            param_name: "bu",
            param: "200",
            roles: ["admin", "management", "user"],
          },
          {
            name: "Openstaande bedragen",
            to: "openstaandebedragen",
            roles: ["admin", "management", "user"],
          },
          {
            name: "RDW Werklijst",
            to: "rdw",
            param_name: "bu",
            param: "200",
            roles: ["it", "management", "binnenkomst"],
          },
          {
            name: "Verkocht per inkoper",
            to: "soldperbuyer",
            param_name: "bu",
            param: "200",
            roles: ["it", "management", "inkoper", "admin"],
          },
        ],
      },
      {
        header_name: "Group",
        sub_menus: [
          {
            name: "Verkopen per week",
            to: "SalesPerWeek",
            roles: ["user"],
          },
          {
            name: "Binnengekomen vandaag",
            to: "Binnengekomen",
            roles: [
              "admin",
              "binnenkomst",
              "finad",
              "kcc",
              "telefonistes",
              "it",
              "hr",
              "comad",
              "verkoper_trucks",
              "verkoper_vans",
              "secretariaat",
              "delivery",
              "terrein",
              "werkplaats",
              "marketing",
              "inkoper",
              "management",
            ],
          },
          {
            name: "Openstaand totaal",
            to: "openstaandebedragentotaal",
            roles: ["admin", "management", "delivery"],
          },
          {
            name: "Trustpilot review",
            to: "Trustpilot",
            roles: ["user"],
          },
          {
            name: "Export documenten",
            to: "exportdocumenten",
            roles: ["user"],
          },
          {
            name: "Truckparts aanvragen",
            to: "truckpartsRequests",
            roles: ["management", "admin", "it",],
          },
          {
            name: "Openstaande inruilers",
            to: "openTradeins",
            roles: ["management", "admin", "it",],
          },
          {
            name: "Open posten per maand",
            to: "OpenPostsPerMonth",
            roles: ["management", "it", "admin"],
          },
          {
            name: "Inkoop provisie lijst",
            to: "ItemBuyProvisionsList",
            roles: ["management", "it", "admin"],
          },
          {
            name: "Notities sales orders",
            to: "salesordersnotities",
            roles: ["management", "it", "admin", "verkoper_trucks", "verkoper_vans", "comad", "delivery", "werkplaats", "transport"],
          }
        ],
      },
      {
        header_name: "Vans",
        sub_menus: [
          {
            name: "Verkoop orders totaal",
            to: "Verkooporders",
            param_name: "bu",
            param: "210",
            roles: ["admin", "management", "delivery"],
          },
          {
            name: "Gereserveerd per inkoper",
            to: "Gereserveerd",
            param_name: "bu",
            param: "210",
            roles: ["admin", "management", "inkoper"],
          },
          {
            name: "Verkoop orders",
            to: "verkooporderperpersoon",
            param_name: "bu",
            param: "210",
            roles: ["admin", "user"],
          },
          {
            name: "Afleveringen per dag",
            to: "afleveringenperdag",
            param_name: "bu",
            param: "210",
            roles: ["admin", "it", "verkopen_vans", "management"],
          },
          {
            name: "Afleveringen per week",
            to: "afleveringenperweek",
            param_name: "bu",
            param: "210",
            roles: ["admin", "management", "delivery"],
          },
          {
            name: "Afleveringen per maand",
            to: "afleveringenpermaand",
            param_name: "bu",
            param: "210",
            roles: ["admin", "user"],
          },
          {
            name: "Openstaande bedragen",
            to: "openstaandebedragen",
            roles: ["admin", "management", "user"],
          },
          {
            name: "RDW Werklijst",
            to: "rdw",
            param_name: "bu",
            param: "210",
            roles: ["it", "management", "binnenkomst"],
          },
          {
            name: "Verkocht per inkoper",
            to: "soldperbuyer",
            param_name: "bu",
            param: "210",
            roles: ["it", "management", "inkoper"],
          },
        ],
      },
    ],
  },
  {
    name: "Inkopen",
    data: [
      {
        header_name: "Trucks",
        sub_menus: [
          {
            name: "Leverancier inzicht totaal",
            to: "leverancierinzicht",
            param_name: "bu",
            param: "200",
            roles: ["admin", "management"],
          },
          {
            name: "Leverancier inzicht",
            to: "leverancierinzichtperpersoon",
            param_name: "bu",
            param: "200",
            roles: ["admin", "management", "inkoper"],
          },
          {
            name: "Inkopen per dag",
            to: "Gekochtvandaag",
            param_name: "bu",
            param: "200",
            roles: [
              "admin",
              "finad",
              "it",
              "comad",
              "verkoper_trucks",
              "inkoper",
              "management",
            ],
          },
          {
            name: "Resultaat per maand",
            to: "resultaten",
            param_name: "bu",
            param: "200",
            roles: ["admin", "it", "secretariaat", "management", "inkoper"],
          },
          {
            name: "Inkopen per land",
            to: "boughtpercountry",
            param_name: "bu",
            param: "200",
            roles: ["admin", "management"],
          },
          {
            name: "Ontbrekende kentekens",
            to: "ontbrekendekentekens",
            param_name: "bu",
            param: "200",
            roles: ["user"],
          },
          {
            name: "Ontvangsten per week",
            to: "ontvangstenperweek",
            param_name: "bu",
            param: "200",
            roles: ["user"],
          },
          {
            name: "Nog binnen te komen",
            to: "openstaandeinkooporders",
            param_name: "bu",
            param: "200",
            roles: ["user"],
          },
          {
            name: "Budget verdeling",
            to: "budgetverdeling",
            param_name: "bu",
            param: "200",
            roles: ["it", "management", "inkoper"],
          },
          {
            name: "Afgeprijsde voertuigen",
            to: "discounteditems",
            param_name: "bu",
            param: "200",
            roles: ["it", "management", "admin"],
          },
          {
            name: "Keuringen per dag",
            to: "itemexaminations",
            param_name: "bu",
            param: "200",
            roles: ["management", "admin", "it", "werkplaats", "inkoper", "verkoper_trucks", "verkoper_vans"],
          },
        ],
      },
      {
        header_name: "Group",
        sub_menus: [
          {
            name: "Verwacht deze week",
            to: "verwachtdezeweek",
            roles: ["user"],
          },
        ],
      },
      {
        header_name: "Vans",
        sub_menus: [
          {
            name: "Leverancier inzicht totaal",
            to: "leverancierinzicht",
            param_name: "bu",
            param: "210",
            roles: ["admin", "management", "duo_vans"],
          },
          {
            name: "Leverancier inzicht",
            to: "leverancierinzichtperpersoon",
            param_name: "bu",
            param: "210",
            roles: ["admin", "management", "inkoper"],
          },
          {
            name: "Inkopen per dag",
            to: "Gekochtvandaag",
            param_name: "bu",
            param: "210",
            roles: [
              "admin",
              "finad",
              "it",
              "comad",
              "verkoper_vans",
              "inkoper",
              "management",
            ],
          },
          {
            name: "Resultaat per maand",
            to: "resultaten",
            param_name: "bu",
            param: "210",
            roles: ["admin", "it", "secretariaat", "management", "inkoper"],
          },
          {
            name: "Inkopen per land",
            to: "boughtpercountry",
            param_name: "bu",
            param: "210",
            roles: ["admin", "management"],
          },
          {
            name: "Ontbrekende kentekens",
            to: "ontbrekendekentekens",
            param_name: "bu",
            param: "210",
            roles: ["user"],
          },
          {
            name: "Ontvangsten per week",
            to: "ontvangstenperweek",
            param_name: "bu",
            param: "210",
            roles: ["user"],
          },
          {
            name: "Nog binnen te komen",
            to: "openstaandeinkooporders",
            param_name: "bu",
            param: "210",
            roles: ["user"],
          },
          {
            name: "Budget verdeling",
            to: "budgetverdeling",
            param_name: "bu",
            param: "210",
            roles: ["it", "management", "inkoper"],
          },
          {
            name: "Afgeprijsde voertuigen",
            to: "discounteditems",
            param_name: "bu",
            param: "210",
            roles: ["it", "management", "admin"],
          },
          {
            name: "Keuringen per dag",
            to: "itemexaminations",
            param_name: "bu",
            param: "210",
            roles: ["management", "admin", "it", "werkplaats", "inkoper", "verkoper_trucks", "verkoper_vans"],
          },
        ],
      },
    ],
  },
  {
    name: "Voorraad",
    data: [
      {
        header_name: "Trucks",
        sub_menus: [
          {
            name: "Voorraad per inkoper",
            to: "voorraad",
            param_name: "bu",
            param: "200",
            roles: ["admin", "finad", "it", "comad", "inkoper", "management"],
          },
          {
            name: "Voorraad",
            to: "stockperperson",
            param_name: "bu",
            param: "200",
            roles: ["user"],
          },
          {
            name: "Mutaties",
            to: "mutations",
            param_name: "bu",
            param: "200",
            roles: ["management", "verkoper_trucks", "verkoper_vans", "admin"],
          },
          {
            name: "Historie laatste jaar",
            to: "stockhistoryyear",
            param_name: "bu",
            param: "200",
            roles: ["management", "verkoper_trucks", "verkoper_vans", "admin"],
          },
          {
            name: "Stand",
            to: "voorraadstand",
            param_name: "bu",
            param: "200",
            roles: ["user"],
          },
          {
            name: "Voertuig wijzigingen",
            to: "voertuigwijzigingen",
            param_name: "bu",
            param: "200",
            roles: ["user"],
          },
          {
            name: "Voorraad op inspectierapport",
            to: "inspectionrapportlist",
            param_name: "bu",
            param: "200",
            roles: ["it", "admin", "management", "inkoper", "verkoper_trucks", "verkoper_vans", "binnenkomst", "werkplaats"],
          },
          {
            name: "Langstaand verkocht",
            to: "VehiclesStockSoldLong",
            param_name: "bu",
            param: "200",
            roles: ["admin","management", "duo_vans", "duo_trucks", "duo_trailers"],
          },
        ],
      },
      {
        header_name: "Group",
        sub_menus: [
          {
            name: "Stadagen voorraad",
            to: "voorraadstadagen",
            roles: ["admin", "management", "marketing", "binnenkomst"],
          },
          {
            name: "Leverancier voorraad/verkopen",
            to: "supplierstocksold",
            roles: ["admin", "management", "it", 'inkoper', "duo_vans"],
          },
        ],
      },
      {
        header_name: "Vans",
        sub_menus: [
          {
            name: "Voorraad per inkoper",
            to: "voorraad",
            param_name: "bu",
            param: "210",
            roles: ["admin", "finad", "it", "comad", "inkoper", "management"],
          },
          {
            name: "Voorraad",
            to: "stockperperson",
            param_name: "bu",
            param: "210",
            roles: ["user"],
          },
          {
            name: "Mutaties",
            to: "mutations",
            param_name: "bu",
            param: "210",
            roles: ["management", "verkoper_trucks", "verkoper_vans", "admin"],
          },
          {
            name: "Historie laatste jaar",
            to: "stockhistoryyear",
            param_name: "bu",
            param: "210",
            roles: ["management", "admin"],
          },
          {
            name: "Stand",
            to: "voorraadstand",
            param_name: "bu",
            param: "210",
            roles: ["user"],
          },
          {
            name: "Voertuig wijzigingen",
            to: "voertuigwijzigingen",
            param_name: "bu",
            param: "210",
            roles: ["user"],
          },
          {
            name: "Voorraad op inspectierapport",
            to: "inspectionrapportlist",
            param_name: "bu",
            param: "210",
            roles: ["it", "admin", "management", "inkoper", "verkoper_trucks", "verkoper_vans", "binnenkomst", "werkplaats"],
          },
          {
            name: "Langstaand verkocht",
            to: "VehiclesStockSoldLong",
            param_name: "bu",
            param: "210",
            roles: ["admin","management", "duo_vans", "duo_trucks", "duo_trailers"],
          },
        ],
      },
    ],
  },
  {
    name: "Resultaat",
    data: [
      {
        header_name: "Trucks",
        sub_menus: [
          {
            name: "Top 20 Klanten",
            to: "top20customers",
            param_name: "bu",
            param: "200",
            roles: ["admin", "it", "management", "verkoper_trucks"],
          },
          {
            name: "Resultaten per inkoper",
            to: "resultaatperinkoper",
            param_name: "bu",
            param: "200",
            roles: ["admin", "it", "management", "inkoper"],
          },
          {
            name: "Resultaten per Verkoper",
            to: "resultaatperverkoper",
            param_name: "bu",
            param: "200",
            roles: ["admin", "it", "management", "inkoper"],
          },
          {
            name: "Marge controle totaal",
            to: "margincontrol",
            param_name: "bu",
            param: "200",
            roles: ["admin", "it", "management"],
          },
          {
            name: "Marge controle",
            to: "margincontrolpersonal",
            param_name: "bu",
            param: "200",
            roles: ["user"],
          },
        ],
      },
      {
        header_name: "Vans",
        sub_menus: [
          {
            name: "Top 20 Klanten",
            to: "top20customers",
            param_name: "bu",
            param: "210",
            roles: ["admin", "it", "management", "verkoper_vans"],
          },
          {
            name: "Resultaten per inkoper",
            to: "resultaatperinkoper",
            param_name: "bu",
            param: "210",
            roles: ["admin", "it", "management", "inkoper"],
          },
          {
            name: "Resultaten per Verkoper",
            to: "resultaatperverkoper",
            param_name: "bu",
            param: "210",
            roles: ["admin", "it", "management", "inkoper"],
          },
          {
            name: "Marge controle totaal",
            to: "margincontrol",
            param_name: "bu",
            param: "210",
            roles: ["admin", "it", "management"],
          },
          {
            name: "Marge controle",
            to: "margincontrolpersonal",
            param_name: "bu",
            param: "210",
            roles: ["user"],
          },
        ],
      },
    ],
  },
  {
    name: "Transport",
    data: [
      {
        header_name: "Trucks",
        sub_menus: [
          {
            name: "Wegbrengen",
            to: "wegbrengen",
            param_name: "bu",
            param: "200",
            roles: [
              "finad",
              "admin",
              "it",
              "comad",
              "verkoper_trucks",
              "secretariaat",
              "delivery",
              "terrein",
              "inkoper",
              "management",
              "transport",
            ],
          },
          {
            name: "Halen",
            to: "halen",
            param_name: "bu",
            param: "200",
            roles: [
              "finad",
              "admin",
              "it",
              "comad",
              "verkoper_trucks",
              "secretariaat",
              "delivery",
              "terrein",
              "inkoper",
              "management",
              "transport",
            ],
          },
          {
            name: "Wordt gebracht",
            to: "wordtgebracht",
            param_name: "bu",
            param: "200",
            roles: [
              "finad",
              "admin",
              "it",
              "comad",
              "verkoper_trucks",
              "secretariaat",
              "delivery",
              "terrein",
              "inkoper",
              "management",
              "transport",
            ],
          },
          {
            name: "Vooruitbetaald",
            to: "vooruitbetaald",
            param_name: "bu",
            param: "200",
            roles: ["admin", "management", "transport"],
          },
          {
            name: "Afleverplanning",
            to: "planning",
            param_name: "bu",
            param: "200",
            roles: ["hr", "management", "it", "admin", "werkplaats", "delivery", "transport"],
          }
        ],
      },
      {
        header_name: "Group",
        sub_menus: [
          {
            name: "Gepland met open Wo's",
            to: "plannedwithopenwo",
            roles: ["hr", "management", "it", "admin", "werkplaats", "delivery", "transport"],
          },
          {
            name: "Wordt gebracht/halen per dag",
            to: "broughtandpickupordersdaily",
            roles: ["hr", "management", "it", "admin", "werkplaats", "delivery", "transport", "binnenkomst"],
          },
          {
            name: "Transport lijst",
            to: "transportlist",
            roles: ["hr", "management", "it", "admin", "werkplaats", "delivery", "transport", "binnenkomst"],
          },
        ],
      },
      {
        header_name: "Vans",
        sub_menus: [
          {
            name: "Wegbrengen",
            to: "wegbrengen",
            param_name: "bu",
            param: "210",
            roles: [
              "finad",
              "admin",
              "it",
              "comad",
              "verkoper_vans",
              "secretariaat",
              "delivery",
              "terrein",
              "inkoper",
              "management",
              "transport",
            ],
          },
          {
            name: "Halen",
            to: "halen",
            param_name: "bu",
            param: "210",
            roles: [
              "finad",
              "admin",
              "it",
              "comad",
              "verkoper_vans",
              "secretariaat",
              "delivery",
              "terrein",
              "inkoper",
              "management",
              "transport",
            ],
          },
          {
            name: "Wordt gebracht",
            to: "wordtgebracht",
            param_name: "bu",
            param: "210",
            roles: [
              "finad",
              "admin",
              "it",
              "comad",
              "verkoper_vans",
              "secretariaat",
              "delivery",
              "terrein",
              "inkoper",
              "management",
              "transport",
            ],
          },
          {
            name: "Vooruitbetaald",
            to: "vooruitbetaald",
            param_name: "bu",
            param: "210",
            roles: ["admin", "management", "transport"],
          },
          {
            name: "Afleverplanning",
            to: "planning",
            param_name: "bu",
            param: "210",
            roles: ["hr", "management", "it", "admin", "werkplaats", "delivery", "transport"],
          }
        ],
      },
    ],
  },
  {
    name: "Landen",
    data: [
      {
        header_name: "Trucks",
        sub_menus: [
          {
            name: "Landen jaaroverzicht",
            to: "landenoverview",
            param_name: "bu",
            param: "200",
            roles: ["user"],
          },
        ],
      },
      {
        header_name: "Vans",
        sub_menus: [
          {
            name: "Landen jaaroverzicht",
            to: "landenoverview",
            param_name: "bu",
            param: "210",
            roles: ["user"],
          },
        ],
      },
    ],
  },
  {
    name: "Werkplaats",
    data: [
      {
        header_name: "Services",
        sub_menus: [
          {
            name: "Controle lijst",
            to: "workshopcontrollist",
            roles: ["hr", "management", "it", "admin", "werkplaats", "transport", "binnenkomst"],
          },
        ],
      },
    ],
  },
  {
    name: "Franchises",
    data: [
      {
        header_name: "A28",
        sub_menus: [
          {
            name: "Verkoop order - A28",
            to: "salesordersfranchise",
            param_name: "franchise",
            param: "A28",
            roles: ["it", "admin", "A28", "management", "duo_vans"],
          },
          {
            name: "Voorraad - A28",
            to: "stockperfranchise",
            param_name: "franchise",
            param: "A28",
            roles: ["it", "admin", "A28", "management", "duo_vans"],
          },
          {
            name: "Resultaten - A28",
            to: "resultsperfranchise",
            param_name: "franchise",
            param: "A28",
            roles: ["it", "admin", "A28", "management", "duo_vans"],
          },
          {
            name: "Afleveringen - A28",
            to: "deliveriesperfranchise",
            param_name: "franchise",
            param: "A28",
            roles: ["it", "admin", "A28", "management", "duo_vans"],
          },
        ],
      },
      {
        header_name: "A73",
        sub_menus: [
          {
            name: "Verkoop order - A73",
            to: "salesordersfranchise",
            param_name: "franchise",
            param: "A73",
            roles: ["it", "admin", "A73", "management"],
          },
          {
            name: "Voorraad - A73",
            to: "stockperfranchise",
            param_name: "franchise",
            param: "A73",
            roles: ["it", "admin", "A73", "management"],
          },
          {
            name: "Resultaten - A73",
            to: "resultsperfranchise",
            param_name: "franchise",
            param: "A73",
            roles: ["it", "admin", "A73", "management"],
          },
          {
            name: "Afleveringen - A73",
            to: "deliveriesperfranchise",
            param_name: "franchise",
            param: "A73",
            roles: ["it", "admin", "A73", "management"],
          },
        ],
      },
      {
        header_name: "Camionette",
        sub_menus: [
          {
            name: "Verkoop order - Camionette",
            to: "salesordersfranchise",
            param_name: "franchise",
            param: "camionette",
            roles: ["it", "admin", "camionette", "management", "duo_vans"],
          },
          {
            name: "Voorraad - Camionette",
            to: "stockperfranchise",
            param_name: "franchise",
            param: "camionette",
            roles: ["it", "admin", "camionette", "management", "duo_vans"],
          },
          {
            name: "Resultaten - Camionette",
            to: "resultsperfranchise",
            param_name: "franchise",
            param: "camionette",
            roles: ["it", "admin", "camionette", "management", "duo_vans"],
          },
          {
            name: "Afleveringen - Camionette",
            to: "deliveriesperfranchise",
            param_name: "franchise",
            param: "camionette",
            roles: ["it", "admin", "camionette", "management", "duo_vans"],
          },
        ],
      },
    ],
  },
  {
    name: "Duo's",
    data: [
      {
        header_name: "Trucks",
        sub_menus: [
          {
            name: "Voorraad rotatie",
            to: "purchaseduosoort",
            param_name: "bu",
            param: "trucks",
            roles: ["it", "admin", "management", "duo_trucks"],
          },
          {
            name: "Stadagen voorraad",
            to: "purchaseduostadagen",
            param_name: "bu",
            param: "trucks",
            roles: ["it", "admin", "management", "duo_trucks"],
          },
          {
            name: "Verkochte voorraad",
            to: "purchaseduosalesorders",
            param_name: "bu",
            param: "trucks",
            roles: ["it", "admin", "management", "duo_trucks"],
          },
          {
            name: "Verkochte voorraad verdeling",
            to: "daysvdistribution",
            param_name: "bu",
            param: "trucks",
            roles: ["it", "admin", "management", "duo_trucks"],
          },
          {
            name: "Inkoop provisie lijst",
            to: "purchaseduoitemprovisionlist",
            param_name: "bu",
            param: "trucks",
            roles: ["it", "admin", "management", "duo_trucks"],
          },
          {
            name: "Inkoop budget mutaties",
            to: "purchaseduomutations",
            param_name: "bu",
            param: "trucks",
            roles: ["it", "admin", "management", "duo_trucks"],
          },
          {
            name: "Verwacht deze week",
            to: "purchaseduoexpectedperweek",
            param_name: "bu",
            param: "trucks",
            roles: ["it", "admin", "management", "duo_trucks"],
          },
          {
            name: "Afleveringen per week",
            to: "purchaseduodeliveriesperweek",
            param_name: "bu",
            param: "trucks",
            roles: ["it", "admin", "management", "duo_trucks"],
          },
          {
            name: "Afleveringen per maand",
            to: "purchaseduodeliveriespermaand",
            param_name: "bu",
            param: "trucks",
            roles: ["it", "admin", "management", "duo_trucks"],
          },
          // {
          //   name: "Inkoop analyse",
          //   to: "purchaseanalysis",
          //   param_name: "bu",
          //   param: "trucks",
          //   roles: ["it", "admin", "management", "duo_trucks"],
          // },
        ],
      },
      {
        header_name: "Trailers",
        sub_menus: [
          {
            name: "Voorraad rotatie",
            to: "purchaseduosoort",
            param_name: "bu",
            param: "trailers",
            roles: ["it", "admin", "management", "duo_trailers"],
          },
          {
            name: "Stadagen voorraad",
            to: "purchaseduostadagen",
            param_name: "bu",
            param: "trailers",
            roles: ["it", "admin", "management", "duo_trailers"],
          },
          {
            name: "Verkochte voorraad",
            to: "purchaseduosalesorders",
            param_name: "bu",
            param: "trailers",
            roles: ["it", "admin", "management", "duo_trailers"],
          },
          {
            name: "Verkochte voorraad verdeling",
            to: "daysvdistribution",
            param_name: "bu",
            param: "trailers",
            roles: ["it", "admin", "management", "duo_trucks", 'duo_trailers'],
          },
          {
            name: "Inkoop provisie lijst",
            to: "purchaseduoitemprovisionlist",
            param_name: "bu",
            param: "trailers",
            roles: ["it", "admin", "management", "duo_trailers"],
          },
          {
            name: "Inkoop budget mutaties",
            to: "purchaseduomutations",
            param_name: "bu",
            param: "trailers",
            roles: ["it", "admin", "management", "duo_trucks"],
          },
          {
            name: "Verwacht deze week",
            to: "purchaseduoexpectedperweek",
            param_name: "bu",
            param: "trailers",
            roles: ["it", "admin", "management", "duo_trailers"],
          },
          {
            name: "Afleveringen per week",
            to: "purchaseduodeliveriesperweek",
            param_name: "bu",
            param: "trailers",
            roles: ["it", "admin", "management", "duo_trucks"],
          },
          {
            name: "Afleveringen per maand",
            to: "purchaseduodeliveriespermaand",
            param_name: "bu",
            param: "trailers",
            roles: ["it", "admin", "management", "duo_trucks"],
          },
          // {
          //   name: "Inkoop analyse",
          //   to: "purchaseanalysis",
          //   param_name: "bu",
          //   param: "trailers",
          //   roles: ["it", "admin", "management", "duo_trailers"],
          // },
        ],
      },
      {
        header_name: "Vans",
        sub_menus: [
          {
            name: "Voorraad rotatie",
            to: "purchaseduosoort",
            param_name: "bu",
            param: "vans",
            roles: ["it", "admin", "management", "duo_vans"],
          },
          {
            name: "Stadagen voorraad",
            to: "purchaseduostadagen",
            param_name: "bu",
            param: "vans",
            roles: ["it", "admin", "management", "duo_vans"],
          },
          {
            name: "Verkochte voorraad",
            to: "purchaseduosalesorders",
            param_name: "bu",
            param: "vans",
            roles: ["it", "admin", "management", "duo_vans"],
          },
          {
            name: "Verkochte voorraad verdeling",
            to: "daysvdistribution",
            param_name: "bu",
            param: "vans",
            roles: ["it", "admin", "management", "duo_trucks", 'duo_vans'],
          },
          {
            name: "Inkoop provisie lijst",
            to: "purchaseduoitemprovisionlist",
            param_name: "bu",
            param: "vans",
            roles: ["it", "admin", "management", "duo_vans"],
          },
          {
            name: "Inkoop budget mutaties",
            to: "purchaseduomutations",
            param_name: "bu",
            param: "vans",
            roles: ["it", "admin", "management"],
          },
          {
            name: "Verwacht deze week",
            to: "purchaseduoexpectedperweek",
            param_name: "bu",
            param: "vans",
            roles: ["it", "admin", "management", "duo_vans"],
          },
          {
            name: "Inkoop analyse",
            to: "purchaseanalysis",
            param_name: "bu",
            param: "vans",
            roles: ["it", "admin", "management", "duo_vans"],
          },
        ],
      },
    ],
  },
];

export default nav_items;
